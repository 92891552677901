import {equation, isInViewport, onScroll} from "./utilities";
import {debounce} from "./debounce";

export default (container) => {
    const vh = document.documentElement.clientHeight // 100vh

    const sections = container.querySelectorAll('.scroll-vertical-slider--inner')
    const captions = container.querySelectorAll('.scroll--caption__item')
    const numberOfSections = sections.length
    const sectionValue = 1 / (numberOfSections - 1)
    const totalHeight = vh * numberOfSections
    const totalAnimationHeight = vh * (numberOfSections - 1)
    container.style.height = `${totalHeight}px`
    container.style.setProperty('--nth-captions', captions.length)

    let progress = 0
    const maxProgressValue = (numberOfSections - 1) / numberOfSections

    sections.forEach((section, i) => container.style.setProperty(`--progress-${i + 1}`, 0))

    let section = 0
    const setSections = (currentSection, progress) => {
        if (currentSection === numberOfSections || currentSection === 0) return

        if (section === currentSection) {
            if (currentSection - 1 !== 0) {
                container.style.setProperty(`--progress-${currentSection - 1}`, `100%`)
            }
            container.style.setProperty(`--progress-${currentSection + 1}`, `0%`)
        }
        section = currentSection

        const progressValue = equation(0, sectionValue * (currentSection - 1), 100, sectionValue * currentSection, progress)
        container.style.setProperty(`--progress-${currentSection}`, `${progressValue}%`)
    }

    const handleScroll = () => {
        const heightTop = container.getBoundingClientRect().top * -1
        console.log(totalAnimationHeight, heightTop)

        if (heightTop >= 0 && heightTop <= totalAnimationHeight) {
            progress = equation(0, 0, 1, totalAnimationHeight, heightTop)
            // if (progress >= maxProgressValue) return
            if (progress >= 1) return
            container.style.setProperty('--progress', `${progress}`)
        }

        if (heightTop < 0) {
            container.style.setProperty(`--progress-1`, `0%`)
            return
        }
        if (heightTop > totalAnimationHeight) {
            container.style.setProperty(`--progress-${numberOfSections - 1}`, `100%`)
            container.style.setProperty(`--progress`, `1`)
            return
        }

        let currentSection = Math.ceil(progress / sectionValue)
        setSections(currentSection, progress)
    }

    onScroll(container, debounce(handleScroll, 5))

    // isInViewport(container, (isIntersecting) => {
    //     if (isIntersecting) document.addEventListener('scroll', handleScroll)
    //     else document.removeEventListener('scroll', handleScroll)
    // })
}
