/**
 * Debounce
 * @param func
 * @param timeout
 * @returns {(function(...[*]): void)|*}
 */
export const debounce = (func, timeout = 300) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func(...args);
        }, timeout);
    };
};
//
//
// export const debounce = (func, timeout = 300) => {
//     let timer;
//     return (...args) => {
//         clearTimeout(timer);
//         timer = setTimeout(() => { func.apply(this, args); }, timeout);
//     };
// }
