import {debounce} from "./debounce";
import {equation, startPageOnTop, findIndex} from "./utilities";

export default function (heroContent, heroElements) {
    startPageOnTop()

    // Setup Elements
    heroElements.forEach(el => el.style.willChange = 'transform')

    const initHeight = heroContent.offsetTop

    let increments = 100
    let dataParallax = []
    for (let i = 0; i < heroElements.length; i++) {
        dataParallax.push(increments)
        increments += 50
    }

    const animationValues = []
    const scrollTopValues = []

    async function calculateAnimation() {
        return new Promise(resolve => {
            const heightTop = heroContent.getBoundingClientRect().top
            const lastValue = heroContent.getBoundingClientRect().height * -1
            let y = heightTop

            while (y > lastValue) {
                scrollTopValues.push(y)
                y--
            }

            heroElements.forEach((el, i) => {
                y = heightTop
                const values = []
                while (y > lastValue) {
                    const x = equation(0, initHeight, dataParallax[i], 0, y)
                    values.push(x)
                    y--
                }
                animationValues.push(values)
            })

            resolve()
        })
    }

    calculateAnimation().then(() => document.body.classList.add('animation-loaded'))

    const animation = () => {
        const heightTop = heroContent.getBoundingClientRect().top
        const currFrame = findIndex(scrollTopValues, heightTop)
        heroElements.forEach((el, i) => el.style.transform = `translate3d(0 ,${animationValues[i][currFrame]}px, 0)`)
    }

    const onScroll = debounce(() => animation(), 6)
    window.addEventListener('scroll', () => animation())
}
