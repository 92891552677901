import {getScreenHeight, onScroll} from "./utilities";

export default (section) => {
    onScroll(section, () => {
        const heightTop = section.getBoundingClientRect().top
        if (heightTop < 0) return

        const screenHeight = getScreenHeight()
        const dataParallax = ((screenHeight - heightTop) / screenHeight).toFixed(3)

        section.style.setProperty('--progress', dataParallax)
    })
}
