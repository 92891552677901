import {debounce} from "./debounce";
import ScrollObserver from "./scrollObserver";

export const startPageOnTop = () => {
    // Force to start page on top
    if (history.scrollRestoration) {
        history.scrollRestoration = 'manual';
    } else {
        window.onbeforeunload = function () {
            window.scroll({top: 0, left: 0});
        }
    }
}

/**
 * Move element to bottom
 */
export function moveElementBottom() {
    const elementsToMove = document.querySelectorAll('.move-bottom-js');
    if (elementsToMove.length) {
        elementsToMove.forEach(el => {
            el.remove()
            document.body.appendChild(el)
        })
    }
}

/**
 * ScrollTop
 * @param el
 * @param value
 * @returns {*}
 */
export function scrollTop(el, value) {
    if (value === undefined) {
        return el.pageYOffset;
    } else {
        if (el === window || el.nodeType === 9) {
            el.scrollTo(el.pageXOffset, value);
        } else {
            el.pageYOffset = value;
        }
    }
}

/**
 * Detect scroll direction
 * @returns {boolean}
 */
let lastScrollTop = 0;
export const isScrollingDown = () => {
    let goingDown = false;
    let st = window.scrollY || document.documentElement.scrollTop;
    goingDown = st > lastScrollTop;
    lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    return goingDown;
};

/**
 * Helper : trigger
 * @param el
 * @param eventType
 */
export function trigger(el, eventType) {
    if (typeof eventType === 'string' && typeof el[eventType] === 'function') {
        el[eventType]();
    } else {
        const event =
            typeof eventType === 'string'
                ? new Event(eventType, {bubbles: true})
                : eventType;
        el.dispatchEvent(event);
    }
}

/**
 * Helper : parents
 * @param el
 * @param selector
 * @returns {*[]}
 */
export function parents(el, selector) {
    const parents = [];
    while ((el = el.parentNode) && el !== document) {
        if (!selector || el.matches(selector)) parents.push(el);
    }
    return parents;
}

/**
 * Trigger lazy load item behaviour
 * @param items
 */
export function setSrcFromDataSrc(items) {
    if (!items || items.length === 0) {
        return
    }
    items.forEach(item => {
        if (!item.hasAttribute('src') && item.hasAttribute('data-src')) {
            item.setAttribute('src', item.dataset.src);
            item.parentNode.classList.add('media-loaded');
        }
        if (!item.hasAttribute('srcset') && item.hasAttribute('data-srcset')) {
            item.setAttribute('srcset', item.dataset.srcset);
        }
    })
}


/**
 * Equation from two points
 * A(x1, y1) B(x2, y2)
 * Value of the y
 * Return the x value
 */
export function equation(x1, y1, x2, y2, y) {
    return (x2 * y - x2 * y1 - x1 * y + x1 * y2) / (y2 - y1);
}

/**
 * Append loader to the DOM
 */
export function addLoader() {
    if (!document.querySelector('.loader')) {
        const loader = document.createElement('div')
        loader.className = 'loader'
        loader.innerHTML = '<div class="loader__inner"></div><div>'
        const main = document.querySelector('main')
        main.appendChild(loader)
    }

    document.body.classList.add('loading')
}

/**
 * Remove loader from the DOM
 */
export function removeLoader() {
    const loader = document.querySelector('.loader');
    if (loader) loader.remove()

    document.body.classList.remove('loading')
}

/**
 * Get the scrollbar width
 */
export function getScrollbarWidth() {
    return window.innerWidth - document.documentElement.clientWidth;
}

/**
 * Await sleep
 */
export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Calculate 100vh
 */
export const getScreenHeight = () => document.documentElement.clientHeight;

/**
 * Find the nearest index in an array of elements
 * @param arr
 * @param value
 * @returns {*}
 */
export const findIndex = (arr, value) => {
    return arr.reduce((nearIndex, currNumber, currIndex) => {
        return Math.abs(arr[nearIndex] - value) > Math.abs(currNumber - value)
            ? currIndex
            : nearIndex;
    }, 0);
}

/**
 * Detect if an element is in viewport
 */
export const isInViewport = (element, callback) => {
    ScrollObserver({
        items: [element],
        rootMargin: "0px 0px",
        threshold: 0
    }, (isIntersecting) => callback(isIntersecting))
}

/**
 * On scroll
 * @param element
 * @param callback
 */
export const onScroll = (element, callback) => {
    isInViewport(element, (isIntersecting) => {
        if (isIntersecting) document.addEventListener('scroll', callback)
        else document.removeEventListener('scroll', callback)
    })
}
