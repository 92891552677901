import {equation, getScreenHeight, startPageOnTop, findIndex, onScroll} from "./utilities";
import {debounce} from "./debounce";
import {matchMediaMDUp} from "./mediaQuery";

export default class scrollVideo {
    constructor(scrollVideoContainer, fps = 5, startAnimationAt = 0) {
        this.scrollVideoContainer = scrollVideoContainer
        this.videoContainer = scrollVideoContainer.querySelector('.scroll-video__container')
        this.video = scrollVideoContainer.querySelector('.scroll-video__video')
        this.fps = fps

        this.scrollTopValues = []
        this.fbfValues = []
        this.isScrolling = null
        this.isSeeking = false

        this.lastTimePercentage = -1
        this.videoDebounce = matchMediaMDUp.matches ? 0.05 : 0.15

        this.video.addEventListener('loadedmetadata', () => {
            this.calculateFbf().then(() => {
                document.body.classList.add('scroll-video-loaded')
                this.init()
            })
        })

        const vh = document.documentElement.clientHeight // 100vh
        this.startAnimationAt = startAnimationAt * vh / 100 // Percentage from top
    }

    async calculateFbf() {
        return new Promise(resolve => {
            this.totalHeight = getScreenHeight() * this.fps - this.startAnimationAt // Total height of the section
            this.totalAnimation = getScreenHeight() * (this.fps - 1)  // Animation end 100vh before (1fps)

            let y = this.startAnimationAt // simula l' heightTop
            const lastValue = this.totalAnimation * -1
            while (y > lastValue) {
                this.scrollTopValues.push(y)
                const x = equation(0, this.startAnimationAt, this.video.duration, (this.totalAnimation + this.startAnimationAt), y) * -1
                this.fbfValues.push(x)
                y--
            }
            resolve()
        })
    }

    init() {
        startPageOnTop()

        this.video.style.height = `${getScreenHeight()}px`
        this.scrollVideoContainer.style.height = this.totalHeight + 'px'

        this.canvas = this.createCanvas()
        this.ctx = this.canvas.getContext('2d')
        this.videoContainer.appendChild(this.canvas)

        const handleScroll = debounce(() => {
            requestAnimationFrame(() => this.frameByFrame());
        }, 15);
        onScroll(this.videoContainer, handleScroll);
    }

    createCanvas() {
        const canvas = document.createElement('canvas')
        canvas.height = this.video.videoHeight
        canvas.width = this.video.videoWidth
        this.setStyle(canvas)
        this.setStyle(this.video)
        this.video.style.display = 'none'
        return canvas
    }

    setStyle(fbf) {
        fbf.style.position = 'absolute'
        fbf.style.top = '50%'
        fbf.style.left = '50%'
        fbf.style.transform = 'translate(-50%, -50%)'
        fbf.style.minWidth = '101%'
        fbf.style.minHeight = '101%'
        // fbf.style.height = '100%'
        fbf.style.width = 'auto'
    }

    updateCanvas() {
        requestAnimationFrame(() => {
            this.ctx.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);
            this.isSeeking = false;
        });
    }

    progress(heightTop) {
        const currFrame = findIndex(this.scrollTopValues, heightTop)
        const newTime = this.fbfValues[currFrame]

        if (Math.abs(this.video.currentTime - newTime) > this.videoDebounce) {
            this.video.currentTime = newTime;
            this.updateCanvas();
        } else {
            this.isSeeking = false
        }

        this.currTimePercentage = this.video.currentTime * 100 / this.video.duration
    }

    resetCanvas() {
        this.video.currentTime = 0;
        this.updateCanvas();
    }

    frameByFrame() {
        if (this.isSeeking) return
        this.isSeeking = true
        const heightTop = this.scrollVideoContainer.getBoundingClientRect().top

        if (heightTop > this.startAnimationAt) {
            this.resetCanvas()
            return
        }

        if ((heightTop * -1) >= (this.totalAnimation - this.startAnimationAt)) {
            this.scrollVideoContainer.classList.add('fbf-completed')
        } else {
            this.scrollVideoContainer.classList.remove('fbf-completed')
        }

        this.progress(heightTop)
    }
}
