import {debounce} from "./debounce";

const scrollObserver = ({
                            items,
                            rootMargin = "-150px 0px",
                            threshold = 0.05,
                            targetClass = "animated",
                            instance = {}
                        }, callback) => {
    if (!items || items.length === 0) return;

    let options = {
        root: null,
        rootMargin,
        threshold,
    };

    let observer = new IntersectionObserver(beTouching, options);
    items.forEach(item => observer.observe(item));

    // Vimeo options
    const vimeoOptions = {
        badge: 0,
    };

    // Cookiebot
    const hasCookiebotMarketing = Cookiebot?.consent?.marketing;

    // Handle Items Intersecting
    function beTouching(entries, ob) {
        entries.forEach(entry => {
            if (callback) {
                callback(entry.isIntersecting)
                return
            }
            if (
                entry.target.classList.contains('vimeo-iframe')
                && entry.target.hasAttribute('data-video-id')
            ) {
                const hasIframe = entry.target.querySelector('iframe');
                let queryString = new URLSearchParams(vimeoOptions).toString();
                queryString += entry.target.classList.contains('card__video-preview') ? '&background=1&loop=1&autopause=0' : '';
                if (entry.isIntersecting && !hasIframe) {
                    let iframe = document.createElement("iframe");
                    const src = `https://player.vimeo.com/video/${entry.target.dataset.videoId}?${queryString}`;
                    if (hasCookiebotMarketing) {
                        iframe.setAttribute('src', src);
                        iframe.setAttribute('data-cookieconsent', 'marketing');
                    } else {
                        iframe.setAttribute('data-cookieblock-src', src);
                        iframe.setAttribute('data-cookieconsent', 'marketing');
                    }
                    entry.target.appendChild(iframe);
                }
            } else if (entry.isIntersecting) {
                entry.target.classList.add(targetClass);
                ob.unobserve(entry.target);
            }
        });
    }

    /**
     * Parallax
     * @type {string}
     */
    let parallaxItems = document.querySelectorAll('.parallax');
    if (parallaxItems.length) {
        const handleParallaxScroll = () => {
            parallaxItems.forEach((item) => {
                const parallaxValue = item.dataset.parallax ?? 10
                const direction = item.dataset.direction === 'down' ? -1 : 1
                let dataParallax = item.getBoundingClientRect().top / parallaxValue * direction + 'px';
                item.style.transform = 'translate3d(0, ' + dataParallax + ', 0)';
            });
        }
        handleParallaxScroll();
        const onScroll = debounce(() => handleParallaxScroll(), 6);
        window.addEventListener("scroll", onScroll);
    }
}

export default scrollObserver;
